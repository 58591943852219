import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';
import moment from 'moment';

const DAY_NAMES = ['月', '火', '水', '木', '金', '土', '日'];
const MONTH_NAMES = [
	'1月',
	'2月',
	'3月',
	'4月',
	'5月',
	'6月',
	'7月',
	'8月',
	'9月',
	'10月',
	'11月',
	'12月',
];
export default {
	name: 'CountDown',
	components: {
		FunctionalCalendar,
	},
	data() {
		return {
			days: '00',
			hours: '00',
			minutes: '00',
			date: '',
			dayNames: DAY_NAMES,
			monthNames: MONTH_NAMES,
			datePicker: {
				selectedDate: '',
				dateRange: {
					start: '',
					end: '',
				},
			},
			timeOut: undefined
		};
	},
	props: {
		dateTime: {
			type: [String, Date],
			required: true,
		},
		enableEdit: {
			type: Boolean,
			default: true,
		},
		showCountDown: {
			type: Boolean,
		},
		isEditAble: {
			type: Boolean,
			default: false,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		readOnlyEditAble: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		dateTime: {
			handler: function (newDateTime) {
				if (newDateTime) {
					this.date = moment(newDateTime).format('YYYY/MM/DD HH:mm');
					this.countDown();
					this.countDownTimer();
				}
			},
			immediate: true,
		},
	},
	methods: {
		countDown() {
			var now = Date.now();
			let daysDiff = moment(this.date).diff(now, 'days');
			let hoursDiff = moment(this.date).diff(now, 'hours') % 24;
			let minutesDiff = moment(this.date).diff(now, 'minutes') % 60;
			let secondsDiff = moment(this.date).diff(now, 'seconds') % 60;
			// Validate minutesDiff base on secondsDiff
			minutesDiff = secondsDiff > 0 ? minutesDiff + 1 : minutesDiff;

			let days = daysDiff > 0 ? daysDiff : '0';
			let hours = hoursDiff > 0 ? hoursDiff : '0';
			let minutes = minutesDiff > 0 ? minutesDiff : '0';

			this.days = days >= 10 || days < 0 ? days : '0' + days;
			this.hours = hours >= 10 || hours < 0 ? hours : '0' + hours;
			this.minutes = minutes >= 10 || minutes < 0 ? minutes : '0' + minutes;
			if (this.days <= 0 && this.hours <= 0 && this.minutes <= 0) {
				this.countDownDone();
			} else {
				this.countDownRunning();
			}
		},
		countDownTimer() {
			this.timeOut = setTimeout(() => {
				this.countDown();
				if (this.days <= 0 && this.hours <= 0 && this.minutes <= 0) {
					this.countDownDone();
					return;
				}
				this.countDownRunning();
				this.countDownTimer();
			}, 500);
		},
		countDownDone() {
			this.days = '00';
			this.hours = '00';
			this.minutes = '00';
			this.$emit('on-time-out-handle');
		},
		countDownSkipped() {
			this.days = '00';
			this.hours = '00';
			this.minutes = '00';
		},
		getCountDown() {
			return this.days, this.hours, this.minutes;
		},
		countDownRunning() {
			this.$emit('on-time-running-handle');
		},
		skipCountDown() {
			clearTimeout(this.timeOut);
			this.countDownSkipped();
		},
		onChange() {
			if (!this.enableEdit) {
				return;
			}
			this.$emit('on-change');
		},

		onReChange() {
			if (!this.readOnlyEditAble) {
				return;
			}
			this.$emit('on-rechange');
		},
	},
};
