const HOUR = 60;
const FIFTEEN_MINUTES = 15;
export default {
	HOUR,
	FIFTEEN_MINUTES,
	DAY_HOURS: 24,
	MINUTES_SELECT_VALUE: Array.from(
		Array(HOUR / FIFTEEN_MINUTES),
		(_, i) => i * FIFTEEN_MINUTES
	),
};
