const IMAGE_SELECTED = 0;
const PROGRESS_READER_LIMIT = 30;
const PROGRESS_POST_LIMIT = 60;
const MAX_PROGRESS_VALUE = 100;
const MIN_PROGRESS_VALUE = 0;
const AI_MIME_TYPE = 'application/postscript';
const PDF_MINE_TYPE = 'application/pdf';

import { isImage, getIcon } from '@/store/modules/Image';
import TransferService from '@/services/API/transfer.service';
import axios from 'axios';

export default {
	name: 'ImageUpload',
	props: {
		path: {
			type: String,
		},
		imgContainerData: {
			type: Object,
		},
		disabled: {
			type: Boolean,
		},
		index: {
			type: Number,
		},
		isEditMode: {
			type: Boolean,
		},
		deleteAble: {
			type: Boolean,
		},
		uploadImageOnly: {
			type: Boolean,
		},
		uploadImageAndPdf: {
			type: Boolean,
		},
		isListImageUpload: {
			type: Boolean,
		},
		biggerUploader: {
			type: Boolean,
			default: false,
		},

		/**
		 * Max file size in MB
		 */
		maxFileSize: {
			type: Number,
			default: undefined,
		},

		/**
		 * Upload file params
		 */
		params: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			preview: undefined,
			showDeleteBtn: false,
			maxProgressValue: MAX_PROGRESS_VALUE,
			progressValue: MIN_PROGRESS_VALUE,
			timer: null,
		};
	},
	computed: {
		backgroundImage() {
			if (this.isListImageUpload) {
				let image = this.path;
				if (!image) {
					return null;
				}
				return `url('${image}')`;
			}

			let image = this.preview || this.path;
			if (!image) {
				return null;
			}
			return `url('${image}')`;
		},

		// Only show image file to input
		imageFileType() {
			let imageTypes = 'image/png, image/gif, image/jpeg, image/jpg, image/svg+xml';
			if (this.uploadImageOnly) return imageTypes;
			else if (this.uploadImageAndPdf) return imageTypes + ',' + ' .pdf';
		},
		uploadFileIconShow() {
			if (this.isListImageUpload) {
				return !this.path;
			} else {
				return !(this.path || this.preview);
			}
		},
		placeholderLabelShow() {
			if (this.isListImageUpload) {
				return !this.path;
			} else {
				return !(this.path || this.preview);
			}
		},
	},

	methods: {
		handleImage(event) {
			if (this.disabled) {
				return;
			}
			let files = event.target.files;
			if (files.length === 0) {
				return;
			}
			if (
				this.maxFileSize != undefined &&
				files[IMAGE_SELECTED].size > this.maxFileSize * 1024 * 1024
			) {
				this.$refs.fileInput.value = '';
				this.$emit('on-max-file-size-error');
				return;
			}
			let reader = new FileReader();
			reader.addEventListener('progress', (progressEvent) =>
				this.onProgress(progressEvent)
			);

			reader.onload = async (file) => {
				this.$emit('on-uploading-file', true);

				try {
					let type = files[IMAGE_SELECTED].type;
					let name = files[IMAGE_SELECTED].name;
					console.log({ type, name });

					if (this.uploadImageOnly && !isImage(type)) {
						this.progressValue = 0;
						return;
					}

					// Handle upload Image and Pdf
					if (this.uploadImageAndPdf && !isImage(type) && type !== PDF_MINE_TYPE) {
						this.progressValue = 0;
						return;
					}

					this.preview = isImage(type) ? file.target.result : getIcon(type, name, true);
					this.showDeleteBtn = true;

					let content = file.target.result;
					console.log({ content });

					let fileKey = await this._uploadImage({
						content,
						file_name: name,
						project_id: parseInt(this.$route.params.projectId),
					});

					console.log({ fileKey });

					let imageData = {
						index: this.index,
						content: content,
						fileKey: fileKey,
						name: files[IMAGE_SELECTED].name,
						event: event,
						type: type.includes('svg') ? 'svg' : type,
					};

					this.$emit('on-send-image-data', imageData);
					this.finishProgress();
				} catch (error) {
					console.log(error);
				}
			};

			reader.readAsDataURL(files[IMAGE_SELECTED]);
		},

		/**
		 * Upload image
		 * @params {Object} file content
		 * @returns uploaded file key
		 */
		async _uploadImage(file) {
			const CancelToken = axios.CancelToken;
			this.source = CancelToken.source();
			let params = { ...file, ...this.params };
			console.log('*****************', params);
			let config = {
				onUploadProgress: (progressEvent) => {
					let { loaded, total } = progressEvent;
					let percent =
						PROGRESS_READER_LIMIT + Math.floor((loaded * PROGRESS_POST_LIMIT) / total);

					this.progressValue = percent;
				},
				cancelToken: this.source.token,
			};

			try {
				let response = await TransferService.post(params, config);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: red');
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},

		onProgress(progressEvent) {
			let { loaded, total } = progressEvent;

			let percent = Math.floor((loaded * PROGRESS_READER_LIMIT) / total);
			this.progressValue = percent;
		},

		onCancelUpload() {
			this.source.cancel();
			this._revertData();
		},

		/**
		 * Revert preview data to empty
		 */
		_revertData() {
			this.preview = undefined;
			this.showDeleteBtn = false;
			this.$refs.fileInput.value = '';
			this.progressValue = MIN_PROGRESS_VALUE;
		},

		onDelete() {
			this._revertData();
			let imageData = {
				index: this.index,
			};
			this.$emit('on-remove-image-data', imageData);
		},

		finishProgress() {
			this.progressValue = this.maxProgressValue;
			this.$refs.fileInput.value = '';

			setTimeout(() => {
				this.progressValue = MIN_PROGRESS_VALUE;
				this.$emit('on-uploading-file', false);
			}, 500);
		},
	},
};
