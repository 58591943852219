var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ img_upload_container: _vm.isEditMode }},[(_vm.progressValue > 0)?_c('div',{staticClass:"progress-bar-container"},[_c('div',{staticClass:"l-progress-bar"},[_c('label',[_vm._v("Uploading...")]),_c('b-progress',{staticClass:"progress-bar--margin",attrs:{"value":_vm.progressValue,"max":_vm.maxProgressValue}})],1),_c('img',{staticClass:"progress-bar__close",attrs:{"src":require("@/assets/ForComponent/RoundCloseBtn.svg"),"alt":""},on:{"click":_vm.onCancelUpload}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressValue === 0),expression:"progressValue === 0"}],staticClass:"photo-upload",class:_vm.disabled ? 'photo-upload__disabled' : 'photo-upload__enabled'},[_c('div',{ref:"uploader",staticClass:"uploader",class:{
				edit_uploader: _vm.isEditMode,
				'uploader-has-files': !_vm.isListImageUpload && (_vm.path || _vm.preview),
				'list-uploader-has-files': _vm.isListImageUpload && _vm.path,
				'__big-uploader': _vm.biggerUploader,
			},style:({ backgroundImage: _vm.backgroundImage })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeleteBtn && _vm.deleteAble),expression:"showDeleteBtn && deleteAble"}],staticClass:"delete-instructions",on:{"click":_vm.onDelete}},[_c('img',{staticClass:"delete-icon",attrs:{"src":require("@/assets/ForComponent/CloseBtn.svg")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadFileIconShow),expression:"uploadFileIconShow"}],staticClass:"upload-instructions"},[_c('img',{staticClass:"upload-file-icon",attrs:{"src":require("@/assets/ForComponent/UploadFileIcon.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadFileIconShow),expression:"uploadFileIconShow"}],staticClass:"placeholder-label",class:{
					edit_placeholder_label: _vm.isEditMode,
				}},[_vm._v(" ファイル選択 ")]),_c('input',{ref:"fileInput",staticClass:"file-photo",class:{ edit_file_photo: _vm.isEditMode },attrs:{"type":"file","accept":_vm.imageFileType,"disabled":_vm.disabled || _vm.showDeleteBtn},on:{"change":_vm.handleImage}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }