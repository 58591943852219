import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import Modal from '@/components/Modal/Modal.vue';
import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';

import TransferService from '../../../../services/API/transfer.service';
import CreativeDetailService from '../../../../services/API/creative_detail.service';

import CommonConst from '../../../../constants/CommonConst';

import moment from 'moment';

const DEFAULT_QUESTION_CONTENT = {
	answer_content: '',
	answer_deadline: '',
	answered_flag: '',
	creative_id: '',
	image_file_name: '',
	question: '',
	update_user: undefined,
	comment: '',
};

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export default {
	name: 'QuestionManagement',
	components: {
		SaveButtonArea,
		ImageUpload,
		Modal,
		FunctionalCalendar,
		SkeletonLoadingImage,
	},
	props: {
		isCreateQuestion: {
			type: Boolean,
		},
		projectId: {
			type: Number,
		},
		updateUser: {
			type: Number,
		},
		creativeId: {
			type: Number,
		},
		selectedQuestion: {
			type: Object,
		},
		isDownloadAvailable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			newImageUploaded: false,
			selectQuestionIndex: '',
			isEditMode: false,
			comentContent: '',
			uploadImageKey: '',
			selectQuestionImg: '',
			errorContents: '',

			questionContent: {
				questionInputContent: '',
				deadLineContent: '',
			},
			datePickerData: {
				dateRange: {
					start: '',
					end: '',
				},
				selectedDate: '',
			},

			uploadFileParams: {
				role: 'creative_detail',
				creative_detail_id: null,
			},

			pdfPreviewModal: 'creative-preview-pdf-modal',
			pdfPreviewer: {
				url: '',
			},
			pdfFileIcon: CommonConst.PDF_FILE_ICON,
		};
	},
	computed: {
		isPdf() {
			return this.selectedQuestion && this.selectedQuestion.fileType === 'pdf';
		},
		disabledSendBtn() {
			return (
				!this.questionContent.questionInputContent ||
				!this.questionContent.deadLineContent
			);
		},
	},
	watch: {
		selectedQuestion: {
			handler() {
				if (!this.isCreateQuestion || this.answeredStatus) {
					this.questionContent.questionInputContent =
						this.selectedQuestion['questionContent'];
					this.selectQuestionImg = this.selectedQuestion['imageUrl'];
					this.questionContent.deadLineContent = this._filterDate(
						new Date(this.selectedQuestion['answerDeadline'])
					);
					this.selectQuestionIndex = this.selectedQuestion['questionIndex'];
					this.uploadImageKey = this.selectedQuestion['fileKey'];
					this.comentContent = this.selectedQuestion['comment'];
				}
			},
		},

		datePickerData: {
			handler() {
				this.questionContent.deadLineContent = this.datePickerData.selectedDate;
			},
			deep: true,
		},

		creativeId(newCreativeId) {
			if (newCreativeId) {
				this.uploadFileParams['creative_detail_id'] = newCreativeId;
			}
		},
	},
	updated() {
		this.updateContentURL();
	},
	methods: {
		onDownloadPdfFileFromChild() { 
			this.$emit('on-download-pdf-file', this.pdfPreviewer);
		},
		updateContentURL() {
			let questionContent = document.getElementById('question-input')
			if (questionContent == null) {
				setTimeout(this.updateContentURL, 100);
			}
			else {
				let stringList = this.questionContent.questionInputContent.split(" ");
				stringList = stringList.map((string) => {
					let stringBr = string.split("\n");
					if(stringBr.length > 1) {
						for (let i = 0; i < stringBr.length; i++) {
							if (isValidHttpUrl(stringBr[i])) {
								let brHeader = stringBr[i - 1] ? "" : "\n";
								let brFooter = stringBr[i + 1] ? "" : "\n";
								stringBr[i] = brHeader + "<a href='" + stringBr[i] + "' target='_blank'>" + stringBr[i] + "</a>" + brFooter;
							}
						}
						return stringBr.join("\n");
					}
					else {
						if (isValidHttpUrl(string)) {
							return "\n<a href='" + string + "' target='_blank'>" + string + "</a>\n";
						}
						else {
							return string
						}
					}
				})
				questionContent.innerHTML = stringList.join(' ');
				let answerContent = document.getElementById('question-answer')
				if (answerContent != null) {
					let stringList = this.selectedQuestion['answeredContents'].split(" ");
					stringList = stringList.map((string) => {
						let stringBr = string.split("\n");
						if(stringBr.length > 1) {
							for (let i = 0; i < stringBr.length; i++) {
								if (isValidHttpUrl(stringBr[i])) {
									let brHeader = stringBr[i - 1] ? "" : "\n";
									let brFooter = stringBr[i + 1] ? "" : "\n";
									stringBr[i] = brHeader + "<a href='" + stringBr[i] + "' target='_blank'>" + stringBr[i] + "</a>" + brFooter;
								}
							}
							return stringBr.join("\n");
						}
						else {
							if (isValidHttpUrl(string)) {
								return "\n<a href='" + string + "' target='_blank'>" + string + "</a>\n";
							}
							else {
								return string
							}
						}
					})
					answerContent.innerHTML = stringList.join(' ');
				}
			}
		},
		onUploadingFile(isUploading) {
			console.log(isUploading);
		},

		onPreview() {
			if (this.isPdf) {
				this.pdfPreviewer.url = this.selectQuestionImg;
				this.$bvModal.show(`${this.pdfPreviewModal}`);
			}
		},

		onChangeDeadLineDate(data) {
			this.questionContent.deadLineContent = data;
		},
		/**
		 * Upload image to S3 when finish reading file
		 */
		async onUploadImage(data) {
			console.log('ON IMAGE DATA HAHAHAHA');
			let params = {
				content: data.content,
				file_name: data.name,
				project_id: this.projectId,
				creative_detail_id: this.creativeId,
				role: 'creative_detail',
			};
			this.uploadImageKey = await this._uploadImage(params);
			this.newImageUploaded = true;
			console.log('UPLOAD IM KEY', this.uploadImageKey);
		},

		onRemoveImageData() {
			console.log('ON REMOVE IMAGE DATA');
			this.uploadImageKey = undefined;
		},

		/**
		 * Upload image
		 * @param {Object} params request objects
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: red', response.data.key);
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},

		async onClose() {
			await this.$bvModal.hide('question-modal');
			this.isEditMode = false;
			this.newImageUploaded = false;
			this._formatContent();
		},

		onHidden() {
			this._formatContent();
		},

		async onSendQuestion() {
			let defaultQuestion = { ...DEFAULT_QUESTION_CONTENT };
			let newQuestionContent = await this._filterQuestionContent(defaultQuestion);
			let response = await CreativeDetailService.post({
				doc_content: newQuestionContent,
			});

			if (!response || response.status !== 200) {
				throw 'Create Question Failed';
			}

			await this.$emit('new-question-add', newQuestionContent);
			this._formatContent();
			this.newImageUploaded = false;
			console.log({ 'Create Question Success': response.data });
			this.$bvModal.hide('question-modal');
		},

		_filterQuestionContent(defaultQuestion) {
			defaultQuestion['update_user'] = parseInt(this.updateUser);
			defaultQuestion['creative_id'] = parseInt(this.creativeId);
			defaultQuestion['question'] = this.questionContent.questionInputContent;
			defaultQuestion['image_file_name'] = this.uploadImageKey;
			defaultQuestion['answered_flag'] = 0;
			defaultQuestion['answer_deadline'] = new Date(
				this.questionContent.deadLineContent
			);
			return defaultQuestion;
		},

		/**
		 * Filter Date to String
		 * @param {DateTime} date
		 * @returns
		 */
		_filterDate(date) {
			if (date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `${year}/${month}/${day}`;
			}
		},

		_formatContent() {
			this.questionContent.questionInputContent = '';
			this.questionContent.deadLineContent = '';
			this.selectQuestionImg = '';
			this.comentContent = '';
			this.datePickerData.selectedDate = undefined;
			this.uploadImageKey = '';
		},

		onEdit() {
			this.isEditMode = !this.isEditMode;
		},

		async onUpdate() {
			let defaultQuestion = { ...DEFAULT_QUESTION_CONTENT };
			let updateQuestion = this._filterQuestionContent(defaultQuestion);
			updateQuestion['id'] = this.selectQuestionIndex;
			let response = await CreativeDetailService.update(this.selectQuestionIndex, {
				doc_content: updateQuestion,
			});

			if (response.status !== 200) {
				throw 'Update question failed';
			}

			console.log('Update question success');
			this.isEditMode = false;
			this.newImageUploaded = false;
			this._formatContent();
			this.$emit('on-update-question', true);
		},
	},
};