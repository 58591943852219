import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';
import constants from '@/constants/DateTimePicker';

import moment from 'moment';

export default {
	name: 'DateTimePicker',
	components: { FunctionalCalendar },
	props: {
		dateTime: {
			type: [String, Date],
		},
		functionalDropDown: {
			type: Boolean,
			default: true,
		},
		showDatePicker: {
			type: Boolean,
			default: true,
		},
		inModal: {
			type: Boolean,
			default: false,
		},
		dropUp: {
			type: Boolean,
			default: false,
		},
		modalCenterCustom: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		}
	},
	directives: {
		clickOutside: {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					// here I check that click was outside the el and his children
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent);
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent);
			},
		},
	},
	data() {
		return {
			dateSelected: {},
			hourSelected: null,
			minuteSelected: null,
			minDate: moment().format('YYYY/MM/DD'),
			hourClicked: false,
			minuteClicked: false,
			...constants,
			currentHour: null,
			currentMinute: null,
			isCurrentDatePicked: false,
		};
	},

	watch: {
		dateTime: {
			handler(newDateTime) {
				if (!newDateTime) return;
				let incomeDate = moment(newDateTime);
				if (this.functionalDropDown) {
					this.dateSelected = {
						selectedDate: incomeDate.format('YYYY-MM-DD'),
						dateRange: {
							start: '',
							end: '',
						},
					};
					this.hourSelected = incomeDate.get('hour');
					this.minuteSelected = incomeDate.get('minute');
				} else {
					this.dateSelected = {
						selectedDate: incomeDate.format('YYYY-MM-DD'),
						dateRange: {
							start: '',
							end: '',
						},
					};
				}
			},
			immediate: true,
		},

		'dateSelected.selectedDate': {
			handler(newSelectedDate) {
				console.log({ newSelectedDate });
				this.isCurrentDatePicked = this.isCurrentDate(newSelectedDate);
				this.currentHour = new Date().getHours();
				this.currentMinute = new Date().getMinutes();

				if (this.isCurrentDatePicked && this.hourSelected < this.currentHour) {
					this.hourSelected = null;
					this.minuteSelected = null;
				}

				this.onPropsChange();
			},
			immediate: true,
		},
		hourSelected: {
			handler(value) {
				if (
					this.isCurrentDatePicked &&
					this.hourSelected === this.currentHour &&
					this.minuteSelected < this.currentMinute
				) {
					this.minuteSelected = null;
				}

				if (value && typeof value !== 'number') {
					value = value.replace(/[０-９]/g, function (s) {
						return String.fromCharCode(s.charCodeAt(0) - 65248);
					});
					let intValue = parseInt(value);
					this.hourSelected = isNaN(intValue) ? null : intValue;
				}

				this.onPropsChange();
			},
			immediate: true,
		},

		minuteSelected: {
			handler(value) {
				if (value && typeof value !== 'number') {
					value = value.replace(/[０-９]/g, function (s) {
						return String.fromCharCode(s.charCodeAt(0) - 65248);
					});
					let intValue = parseInt(value);
					this.minuteSelected = isNaN(intValue) ? null : intValue;
				}
				this.onPropsChange();
			},
		},
	},
	computed: {
		isRequiredHour() {
			if (!isNaN(this.hourSelected) && this.hourSelected != null) {
				return false;
			}	
			return true
		},
		isRequiredMinute() {
			if (!isNaN(this.minuteSelected) && this.minuteSelected !== null) {
				return false;
			}
			return true
		}
	},
	methods: {
		onPropsChange: function () {
			this.$emit('on-props-change', {
				dateSelected: this.dateSelected,
				hourSelected: this.hourSelected,
				minuteSelected: this.minuteSelected,
			});
		},
		onHourClick() {
			if (this.showDatePicker && !this.dateSelected.selectedDate) return;

			this.hourClicked = !this.hourClicked;
		},

		optionHourClick(data) {
			this.hourSelected = data;
		},

		onMinuteClick() {
			if (this.showDatePicker && [undefined, null, ''].includes(this.hourSelected))
				return;

			this.minuteClicked = !this.minuteClicked;
		},

		optionMinuteClick(data) {
			this.minuteSelected = data;
		},

		clickOutSideHour() {
			if (this.hourClicked) {
				this.hourClicked = false;
			}
		},
		clickOutSideMinute() {
			if (this.minuteClicked) {
				this.minuteClicked = false;
			}
		},

		isCurrentDate(dateTime) {
			const today = new Date();

			return today.toLocaleDateString() === new Date(dateTime).toLocaleDateString();
		},

		resetData() {
			this.hourSelected = '';
			this.minuteSelected = ''
		}
	},
	mounted() {
		if (this.inModal) {
			this.dateSelected = {
				selectedDate: moment(this.dateTime).format('YYYY-MM-DD'),
				dateRange: {
					start: '',
					end: '',
				},
			};
			this.hourSelected = moment(this.dateTime).get('hour');
			this.minuteSelected = moment(this.dateTime).get('minute');
		}

		// if (!this.functionalDropDown) {
		// 	this.hourSelected = '0';
		// 	this.minuteSelected = '00';
		// }
	},
};
