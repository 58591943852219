export default {
	name: 'SaveAndCancelButtonArea',
	props: {
		isSaveButtonShowed: {
			type: Boolean,
		},
		isCancelButtonShowed: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			availableSaveWhenClick: false,
			availableSaveValidate: false,
			availableCancelWhenClick: false,
			availableCancelValidate: false,
		};
	},

	methods: {
		onSaveButtonClick(event) {
			this.$emit('on-save-button-trigger', event);
			this.availableSaveWhenClick = !this.availableSaveWhenClick;
			setTimeout(() => {
				this.availableSaveWhenClick = !this.availableSaveWhenClick;
				this.availableSaveValidate = !this.availableSaveValidate;
			}, 0);
			setTimeout(() => {
				this.availableSaveValidate = !this.availableSaveValidate;
			}, 1000);
		},
		onCancelButtonClick(event) {
			this.$emit('on-cancel-button-trigger', event);
			this.availableCancelWhenClick = !this.availableCancelWhenClick;
			setTimeout(() => {
				this.availableCancelWhenClick = !this.availableCancelWhenClick;
				this.availableCancelValidate = !this.availableCancelValidate;
			}, 0);
			setTimeout(() => {
				this.availableCancelValidate = !this.availableCancelValidate;
			}, 1000);
		},
	},
	created() {
		document.addEventListener('touchstart', (event) => {
			if (event.target.matches('buttonGroup__markContainer')) {
				document.getElementsByClassName('buttonGroup__toolTip')[0].classList.add('buttonGroup__toolTip--active');
			}
		})
		document.addEventListener('touchend', (event) => {
			if (event.target.matches('buttonGroup__markContainer')) {
				document.getElementsByClassName('buttonGroup__toolTip')[0].classList.remove('buttonGroup__toolTip--active');
			}
		})
	}
};
