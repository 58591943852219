module.exports = {
	getFileExtention(key) {
		if (key) {
			const arr = key.split('.');
			return arr[arr.length - 1];
		}
	},

	getFileName(key) {
		if (key) {
			const arr = key.split('/');
			return arr[arr.length - 1];
		}
	},
};
