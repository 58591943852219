import ApiService from './api.service';

const serviceURL = 'creative_detail';
const CreativeDetailService = {
	/**
	 * Create new creative record
	 * @param {Object} body {doc_content: {creative Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update creative record
	 * @param {Integer} creativeDetailId Project ID
	 * @param {Object} body {doc_content: {creative Object}}
	 * @returns {Object} success message
	 */
	update(creativeDetailId, body) {
		return ApiService.put(`${serviceURL}/${creativeDetailId}`, body);
	},

	/**
	 * Delete creativeDetail
	 * @param {Integer} id creative_detail ID
	 * @returns Success message
	 */
	delete(id) {
		return ApiService.delete(`${serviceURL}/${id}`);
	},
};

export default CreativeDetailService;
