export default {
	name: 'SaveButtonArea',
	props: {
		isSaveButtonShowed: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		buttonLabel: {
			type: String,
			default: '保存',
		},
		buttonMainColor: {
			type: String,
			default: '#cca872',
		},
		isShowDescription: {
			type: Boolean,
			default: true,
		},
		labelMessage: {
			type: String,
			default: 'を押下すると、ブラウザを閉じても内容が保存されます',
		},
		buttonType: {
			type: String,
			default: 'save',
		},
		buttonTooltip: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		style() {
			return {
				'--label': "'" + this.buttonLabel + "'",
				'--button-main-color': this.buttonMainColor,
			};
		},
	},
	methods: {
		onSaveButtonClick(event) {
			this.$emit('on-save-button-trigger', event);
		},
	},
	created() {
		document.addEventListener('touchstart', (event) => {
			if (event.target.matches('buttonGroup__markContainer')) {
				document
					.getElementsByClassName('buttonGroup__toolTip')[0]
					.classList.add('buttonGroup__toolTip--active');
			}
		});
		document.addEventListener('touchend', (event) => {
			if (event.target.matches('buttonGroup__markContainer')) {
				document
					.getElementsByClassName('buttonGroup__toolTip')[0]
					.classList.remove('buttonGroup__toolTip--active');
			}
		});
	},
};
