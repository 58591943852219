import ProjectService from '@/services/API/project.service';
import socketClient from '@/services/SOCKET';

import { mapState } from 'vuex';

export default {
	computed: {
		...mapState(['creatorInfo', 'projectInfo']),
	},

	watch: {
		creatorInfo(newCreatorInfo) {
			if (newCreatorInfo?.id) {
				socketClient.send('join', {
					userId: newCreatorInfo.id,
					room: 'user' + newCreatorInfo.id,
				});
			}
		},
	},
	methods: {
		/**
		 * Update NEXT processing screen status
		 * @param {Integer} projectId
		 * @param {Integer} screenId
		 */
		async updateProjectStatus(projectId, screenId) {
			try {
				let response = await ProjectService.update_status(projectId, screenId);
				if (response.status !== 200) {
					throw 'Update project status failed';
				}
				console.log('%c Update project status successfully', 'color: green');
				let projectInfo = this.projectInfo;
				projectInfo.status = screenId;
				this.$store.commit('setProjectInfo', projectInfo);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.creatorInfo.id,
						event_name: 'update_project_status',
					},
					room: 'user' + this.creatorInfo.id,
				});
				console.log('send data transfer successfully');
			} catch (error) {
				console.log(`%c - Error: ${error}`, 'color:red');
			}
		},
	},
};
