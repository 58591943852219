import Modal from '@/components/Modal/Modal.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

import CommonConst from '@/constants/CommonConst';
import { mapState } from 'vuex';

function isValidHttpUrl(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return url.protocol === 'http:' || url.protocol === 'https:';
}

export default {
	name: 'ItemList',
	components: {
		Modal,
		SkeletonLoadingImage,
		NotificationModal,
	},
	props: {
		allowAdditions: {
			type: Boolean,
		},
		itemList: {
			type: Array,
		},
		addItemBtn: {
			type: String,
		},
		itemContainerClass: {
			type: String,
		},
		isSaved: {
			type: Boolean,
		},
		isSelective: {
			type: Boolean,
		},
		isAddButtonDisabled: {
			type: Boolean,
		},
		descriptionLabel: {
			type: String,
		},
		descriptionSkip: {
			type: String,
		},
		isShowDeleteButton: {
			type: Boolean,
		},
		isReadOnly: {
			type: Boolean,
		},
		isShowNoneQuestionNoti: {
			type: Boolean,
		},
		isShowUploadStatus: {
			type: Boolean,
		},
		isDownloadAvailable: {
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			sortItemList: [],
			previewItem: {},
			imagePreviewModal: 'item-list-preview-modal',
			selectiveModalId: 'skip-proposal-modal',
			pdfPreviewModal: 'item-list-preview-pdf-modal',
			pdfPreviewer: {
				url: '',
			},

			pdfFileIcon: CommonConst.PDF_FILE_ICON,
			selectiveContents: CommonConst.MODAL_CONTENTS.CONFIRM_SKIP,
		};
	},
	computed: {
		...mapState(['userId', 'creatorInfo', 'clientMode']),
		showQuestionDeleteButtonByUser() {
			return !!(this.userId && this.creatorInfo && this.userId === this.creatorInfo.id);
		},
	},
	watch: {
		itemList: {
			handler() {
				let itemList = [...this.itemList];

				itemList.map((item, index) => {
					this.$set(item, 'rawIndex', index);
				});
				console.log(itemList);
				this.sortItemList = [];
				this.$nextTick(() => {
					this.sortItemList = this._checkChanged(itemList);
				});
			},
		},
	},
	updated() {
		this.sortItemList.map((item, idx) => {
			let modalComment = document.getElementById('comment-content-' + idx);
			if (modalComment != null) {
				let stringList = item.commentContent.split(' ');
				stringList = stringList.map((string) => {
					let stringBr = string.split('\n');
					if (stringBr.length > 1) {
						for (let i = 0; i < stringBr.length; i++) {
							if (isValidHttpUrl(stringBr[i])) {
								let brHeader = stringBr[i - 1] ? '' : '\n';
								let brFooter = stringBr[i + 1] ? '' : '\n';
								stringBr[i] =
									brHeader +
									"<a href='" +
									stringBr[i] +
									"' target='_blank'>" +
									stringBr[i] +
									'</a>' +
									brFooter;
							}
						}
						return stringBr.join('\n');
					} else {
						if (isValidHttpUrl(string)) {
							return "\n<a href='" + string + "' target='_blank'>" + string + '</a>\n';
						} else {
							return string;
						}
					}
				});
				modalComment.innerHTML = stringList.join(' ');
			}
		});
	},
	methods: {
		onDownloadPdfFromChild() { 
			this.$emit('on-download-pdf-file', this.pdfPreviewer);
		},
		onCreate() {
			this.$emit('on-create-item');
		},

		/**
		 * Handle when skip proposal/reproposal
		 */
		onClickSkip() {
			this.$bvModal.show(this.selectiveModalId);
		},

		/**
		 * Handle when skip proposal/reproposal
		 */
		onSkip() {
			this.$emit('on-click-skip');
		},

		onSelect(index) {
			let itemInfo = {
				id: this.sortItemList[index]['itemId'],
			};
			this.$emit('on-select-item', itemInfo);
		},

		/**
		 * Handle when click to arrow on item
		 * @param {Integer} index item index
		 * @param {Boolean} isOpen item is open or close
		 * @returns
		 */
		onArrowSelect(index, isOpen) {
			if (!isOpen) return;

			//Prevent onEntireBarSelect() executes
			setTimeout(() => this.onSelect(index), 100);
		},

		/**
		 * Handle when click on item when close
		 * @param {Integer} index item index
		 * @param {Boolean} isOpen item is open or close
		 * @returns
		 */
		onEntireBarSelect(index, isOpen) {
			if (isOpen) return;
			let itemInfo = {
				id: this.sortItemList[index]['itemId'],
			};
			this.$emit('on-select-item', itemInfo);
		},

		onEvaluation(index) {
			let itemInfo = {
				id: this.sortItemList[index]['itemId'],
				itemIdx: index,
				isCreatNew: false,
			};
			this.$emit('on-evaluation-item', itemInfo);
		},

		onEdit(index) {
			let itemInfo = {
				id: this.sortItemList[index]['itemId'],
				itemIdx: index,
				isCreatNew: false,
			};
			this.$emit('on-edit-item', itemInfo);
		},

		onDeleteButtonClick(index, questionId) {
			console.log({ closeIndx: index });
			this.$emit('on-delete-item', { index: index, questionId: questionId });
		},

		async onPreviewButtonClick(data) {
			if (this.clientMode) return;
			if (data.fileType === 'pdf') {
				this.pdfPreviewer.url = data.imageUrl;
				this.$bvModal.show(this.pdfPreviewModal);
				return;
			}
			if (data.imageUrl.includes('.svg')) {
				let base64Img = await this._toDataURL(data.imageUrl);
				data.imageUrl = base64Img.replace('binary/octet-stream','image/svg+xml');
				this.previewItem = data
			}
			else {
				this.previewItem = data;				
			}
			this.$bvModal.show(this.imagePreviewModal);
		},
		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},
		_checkChanged(itemList) {
			let changedItem = [];
			let waitChange = [];
			let savedItem = [];
			let readedItem = [];
			for (let i = 0; i < itemList.length; i++) {
				if (itemList[i]['isChanged'] && !itemList[i]['isSaved']) {
					changedItem.push(itemList[i]);
				}

				// Check if item in Revison Screen after evolution have been Saved or Not
				if (itemList[i]['isSaved']) {
					savedItem.push(itemList[i]);
				}

				if (itemList[i]['isReaded']) {
					readedItem.push(itemList[i]);
				}

				if (!itemList[i]['isChanged'] && !itemList[i]['isSaved']) {
					waitChange.push(itemList[i]);
				}
			}

			// let isNewItem = true;
			waitChange = this._sortByDeadLine(waitChange);

			// If item have been changed status, will sort by date_modified from the earliest item
			changedItem = this._sortByDeadLine(changedItem);
			savedItem = this._sortByDeadLine(savedItem);
			return waitChange.concat(changedItem).concat(savedItem);
		},

		_sortByDeadLine(itemList) {
			itemList.sort((a, b) => {
				return new Date(b.nonfilterDateData) - new Date(a.nonfilterDateData);
			});
			// console.log('Sort by answered deadline', itemList);
			return itemList;
		},
	},
};
