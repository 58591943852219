import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import SaveAndCancelButtonArea from '@/components/SaveAndCancelButtonArea/SaveAndCancelButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import DateTimePicker from '@/components/DateTimePicker/DateTimePicker.vue';
import QuestionModal from './QuestionModal/QuestionModal.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import Modal from '@/components/Modal/Modal.vue';

import CreativeService from '@/services/API/creative.service';
import socketClient from '@/services/SOCKET';
import ProjectService from '@/services/API/project.service';
import CreativeDetailService from '@/services/API/creative_detail.service';
import TransferService from '@/services/API/transfer.service';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import { getFileName, getFileExtention } from '@/store/modules/File';
import CommonConst from '@/constants/CommonConst';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import { formatToStandardDate } from '../../../store/modules/DateTime';
import emitSoundMixin from '@/mixin/emitSound';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

import moment from 'moment';
import { mapState } from 'vuex';

const SELECTIVE_NOTI_CONTENTS = '「 ご提案 」 に進んで提案準備をしますか？';
const CONFIRM_DELETE_CONTENTS = '削除してもよろしいですか？';

const DEFAULT_ITEM = {
	title: undefined,
	isChanged: undefined,
	isReaded: undefined,
	dateData: undefined,
	nonfilterDateData: undefined,
	itemId: undefined,
	commentContent: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	showDateData: true,
	showTitle: true,
	statusIcon: 'QuestionStatus.png',
	isShowIcon: false,
	isColorChanged: false,
};
export default {
	name: 'CreativeScreen',
	mixins: [updateProjectStatusMixin, emitSoundMixin, downloadPdfMixin],
	props: { mobileLiveScreenMinimum: Boolean },
	components: {
		ScreenHeader,
		ProjectDeadline,
		SaveButtonArea,
		BottomButton,

		NotificationModal,
		CountDown,
		DateTimePicker,
		SaveAndCancelButtonArea,
		ItemList,
		QuestionModal,
		Modal,
	},
	data() {
		return {
			screenIcon: 'creative-icon',
			screenName: 'クリエイティブ',
			creativeButtonName: 'ご提案',
			directLink: 'proposal',
			middleContent: ' 様 ／ ',
			selectiveContents: SELECTIVE_NOTI_CONTENTS,
			selectiveModalId: 'creative-selective-modal',
			confirmDeleteModalContents: CONFIRM_DELETE_CONTENTS,
			confirmDeleteQuestionModalId: 'confirm-delete-question-modal',
			currentDeletingQuestion: undefined,

			suggestionDate: '',
			deadlineNotification: '',
			counterDate: new Date().toDateString(),
			errorContents: '',
			hourSelected: '',
			minuteSelected: '',
			scheduleDate: '',

			selectedQuestion: {},
			dateSelected: {},
			questionList: [],

			isSaved: false,
			isEdit: false,
			creativeData: false,
			isDisableSetupBtn: true,
			disabledUpload: false,
			creativeId: undefined,
			isCreateQuestion: true,
			allowAdditions: true,
			isEditAble: false,

			showQuestionContainer: false,
			showBottomButton: false,
			showCountDown: true,
			isShowDeleteButton: true,

			creativeStatus: '',
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		// Get projectId. userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}creative`
						: `${this.projectInfo['process_client_url']}creative`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}creative`
					: `${this.projectInfo['process_client_url']}creative`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	methods: {
		onDownloadPdfFileFromParent(pdfUrl) { 
			this.pdfPreviewer = pdfUrl
			console.log("creative screen pdf url: ", pdfUrl);
			this.onDownloadPdfFile();
		},
		onDirectBtnClick() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		// For Create and Read Answered Question
		onCreate() {
			this.isCreateQuestion = true;
			this.$bvModal.show('question-modal');
		},
		/**
		 * Trigger when click in Question item
		 * @param {String} data:  Selected item data
		 */
		async onSelect(data) {
			this.$store.commit('setIsAppProcessing', true);

			this.isCreateQuestion = data['isCreatNew'];
			const creativeData = { ...this.creativeData };
			const creativeDetails = creativeData['creative_details'];
			const selectedQuestion = creativeDetails.find((x) => x.id === data['id']);
			const imageUrl = selectedQuestion['image_file_name']
				? await this._getPreviewImgUrl(
						selectedQuestion['image_file_name'],
						CommonConst.ONE_WEEK_SECONDS
				  ) // eslint-disable-line no-mixed-spaces-and-tabs
				: '';

			const question = {
				answeredStatus: Boolean(selectedQuestion['answered_flag']),
				readedStatus: Boolean(selectedQuestion['readed_flag']),
				answeredContents: selectedQuestion['answer_content'],
				comment: selectedQuestion['comment'],
				questionContent: selectedQuestion['question'],
				imageUrl: imageUrl,
				answerDeadline: selectedQuestion['answer_deadline'],
				questionIndex: selectedQuestion['id'],
				fileKey: selectedQuestion['image_file_name'],
				fileType: getFileExtention(selectedQuestion['image_file_name']),
				fileName: getFileName(selectedQuestion['image_file_name']),
			};

			if (question.answeredStatus) {
				// Update readed_flag when click in answered question
				selectedQuestion['readed_flag'] = 1;
				let response = await CreativeDetailService.update(selectedQuestion['id'], {
					doc_content: selectedQuestion,
				});
				if (response.status !== 200) {
					throw 'Update Creative detail failed';
				}
				this.$emit('update-creative-question-list', true);
				question['readedStatus'] = true;
			}

			this.selectedQuestion = question;
			await this._getCreativeData(this.projectId);
			this.$bvModal.show('question-modal');

			this.$store.commit('setIsAppProcessing', false);
		},

		onDeleteQuestion(data) {
			this.currentDeletingQuestion = data;
			this.$bvModal.show(`${this.confirmDeleteQuestionModalId}`);
		},

		async onConfirmDeleteQuestion() {
			this.$store.commit('setIsAppProcessing', true);

			let question = this.currentDeletingQuestion;

			this.questionList.splice(question.index, 1);
			await this._deleteCreativeDetail(question.questionId);
			socketClient.send('update_creative_question_list', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});
			this.$emit('update-creative-question-list', true);

			this.currentDeletingQuestion = undefined;

			this.$store.commit('setIsAppProcessing', false);
		},

		async onUpdateNewQuestion(data) {
			console.log('questionData', data);
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				let creativeData = { ...this.creativeData };
				this._filterQuestionList(creativeData);
				await this._getCreativeData(this.projectId);
				socketClient.send('update_creative_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				let deadline = moment(data['answer_deadline']).format('YYYY年MM月DD日');
				// send mail case 36
				this.isSendCreator = false;
				this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 追加質問が届きました`;
				this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 追加質問が届きました`;
				this.mailContent = `追加質問が届きました。\n(回答期限 ${deadline})`;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
				]);
			}
			this.$emit('update-creative-question-list', true);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Update Answer from Client
		 * @param {Boolean} data: Get client answered status by socket io
		 */
		async onUpdateSelectedQuestion(data) {
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				await this._getCreativeData(this.projectId);
				socketClient.send('update_creative_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				this.$refs.questionModal.onClose();
			}
			this.$emit('update-creative-question-list', true);
			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Filter question list
		 * @param {Object} creativeData
		 */
		async _filterQuestionList(creativeData) {
			let creativeDetails = [];
			let questionList = [];
			creativeDetails = creativeData['creative_details'] ?? [];
			for (let i = 0; i < creativeDetails.length; i++) {
				let defaultQuestion = { ...DEFAULT_ITEM };
				//if answered_flag = 1 is mean client had been answer the question then isChanged = true else isChanged = false
				defaultQuestion['isChanged'] = Boolean(creativeDetails[i]['answered_flag']);

				//if readed_flag = 1 is mean creator had been read the answer then isReaded = true else isReaded = false
				defaultQuestion['isReaded'] = Boolean(creativeDetails[i]['readed_flag']);
				if (defaultQuestion['isChanged']) {
					defaultQuestion['gridCols'] = 2;
					defaultQuestion['gridStatus'] = 'normal';
				} else {
					defaultQuestion['gridCols'] = 2;
					defaultQuestion['gridStatus'] = 'normal';
				}

				defaultQuestion['title'] = creativeDetails[i]['question'];
				if (defaultQuestion['isChanged'] && !defaultQuestion['isReaded']) {
					defaultQuestion['dateData'] = 'クライアントから回答がきています';
					defaultQuestion['isColorChanged'] = true;
				} else if (defaultQuestion['isReaded']) {
					defaultQuestion['gridCols'] = 3;
					defaultQuestion['gridStatus'] = 'normal';
					defaultQuestion['isShowIcon'] = true;
					defaultQuestion['dateData'] = '回答済み';
				} else {
					defaultQuestion['dateData'] = this._filterDate(
						creativeDetails[i]['answer_deadline'],
						defaultQuestion['isChanged']
					);
					defaultQuestion['isColorChanged'] = false;
				}
				defaultQuestion['nonfilterDateData'] = creativeDetails[i]['answer_deadline'];
				defaultQuestion['itemId'] = creativeDetails[i]['id'];
				defaultQuestion['commentContent'] = creativeDetails[i]['comment'];

				questionList.push(defaultQuestion);
			}
			this.questionList = questionList;
			// console.log('UPDATE QUESTION LIST', this.questionList);
		},

		/**
		 * Filter Date to String
		 * @param {DateTime} date
		 * @returns
		 */
		_filterDate(date) {
			if (date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `回答期限 [${year} 年 ${month} 月 ${day} 日]`;
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Delete Creative Detail by id
		 * @param {Integer} id CreativeDetail Id
		 */
		async _deleteCreativeDetail(id) {
			try {
				let response = await CreativeDetailService.delete(id);
				if (!response || response.status !== 200) {
					throw 'Delete Creative Detail failed!';
				}

				console.log('%c Delete Creative Detail successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		//For Add Count Down
		async onDirect() {
			this.$store.commit('setIsAppProcessing', true);

			// emit sound when user confirm navigate to ProposalScreen
			this.emitMixinProcessingSound({ isSocketIncluded: false });

			let creativeStatus = {
				project_id: this.projectId,
				status: 'onCountingDown',
				update_user: this.userId,
			};

			await this._updateCreative(this.projectId, creativeStatus);
			await this.updateProjectStatus(
				this.projectId,
				CommonConst.SCREEN_ID.CREATIVE + 1
			);
			let confirmInfo = {
				screenId: CommonConst.SCREEN_ID.CREATIVE,
				confirmInfo: true,
			};
			this.$emit('on-send-confirm-info', confirmInfo);

			// let projectInfo = { ...this.projectInfo, status: CommonConst.SCREEN_ID.CREATIVE + 1 };
			this.$emit('on-update-project-status', CommonConst.SCREEN_ID.CREATIVE + 1);
			// this.$store.commit('setProjectInfo', projectInfo);

			this.$store.commit('setIsAppProcessing', false);
			this.$router.push(this.directLink);
		},

		async onHandleTimeOut() {
			this.$store.commit('setIsAppProcessing', true);

			// Abort when creative is dose not existed
			if (!this.creativeId) {
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			if (this.creativeStatus !== CommonConst.SCREEN_STATUS.FINISH) {
				let creativeStatus = {
					project_id: this.projectId,
					status: CommonConst.SCREEN_STATUS.FINISH,
					update_user: this.userId,
				};

				this.showCountDown = false;
				this.showBottomButton = false;
				this.allowAdditions = false;
				this.isEditAble = false;

				await this._updateCreative(this.projectId, creativeStatus);

				// If current in Creative screen -> Update project and direct to proposal screen
				let projectStatus = await this._getProjectStatus(this.projectId);
				if (projectStatus === CommonConst.SCREEN_ID.CREATIVE) {
					await this.updateProjectStatus(
						this.projectId,
						CommonConst.SCREEN_ID.CREATIVE + 1
					);
					let confirmInfo = {
						screenId: CommonConst.SCREEN_ID.CREATIVE,
						confirmInfo: true,
					};
					this.$emit('on-send-confirm-info', confirmInfo);
					this.$router.push(this.directLink);
				}
			}

			this.$store.commit('setIsAppProcessing', false);
		},
		onHandleTimeRunning() {
			this.showCountDown = true;
		},
		async onSave() {
			this.$store.commit('setIsAppProcessing', true);
			if (
				this.dateSelected.selectedDate === '' ||
				this.hourSelected === '' ||
				this.minuteSelected === ''
			) {
				this.isDisableSetupBtn = true;
			}

			let creativeDeadLine = {
				project_id: this.projectId,
				scheduled_date: new Date(
					formatToStandardDate({
						date: this.dateSelected.selectedDate,
						hour: this.hourSelected,
						minute: this.minuteSelected,
					})
				),
				update_user: this.userId,
				status: 'onCreateCountDown',
			};
			console.log({ creativeDeadLine });
			await this._createCreative(creativeDeadLine);
			await this._getCreativeData(this.projectId);
			this.isSaved = true;
			this.showCountDown = true;
			socketClient.send('creative_created', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});
			// send mail case 33
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］提案日時が決まりました`;
			this.mailContent = `提案日時が決定しました\n${moment(
				this.dateSelected.selectedDate +
					' ' +
					this.hourSelected +
					':' +
					this.minuteSelected
			).format('YYYY年MM月DD日 HH:mm')} に\nCo-modeを開いてください。`;
			this.isSendCreator = true;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 提案日時が決まりました`;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 提案日時が決まりました`;
			this.mailContent = `提案日時が決定しました\n${moment(
				this.dateSelected.selectedDate +
					' ' +
					this.hourSelected +
					':' +
					this.minuteSelected
			).format('YYYY年MM月DD日 HH:mm')} に\nCo-modeを開いてください。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 提案日時が決まりました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);
			this.$emit('start-creative-countdown')
			this.$store.commit('setIsAppProcessing', false);
		},

		async onUpdate() {
			this.$store.commit('setIsAppProcessing', true);

			// Abort when creative is dose not existed
			if (!this.creativeId) {
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			if (
				this.dateSelected.selectedDate === '' ||
				this.hourSelected === '' ||
				this.minuteSelected === ''
			) {
				this.isDisableSetupBtn = true;
			}
			let minutes =
				this.minuteSelected >= 10 ? this.minuteSelected : '0' + this.minuteSelected;
			let hours = this.hourSelected >= 10 ? this.hourSelected : '0' + this.hourSelected;
			let creativeDeadline = {
				project_id: this.projectId,
				scheduled_date: new Date(
					moment(this.dateSelected.selectedDate + ' ' + hours + ':' + minutes)
				),
				update_user: this.userId,
				status: 'onUpDateCountDown',
			};
			this.counterDate = creativeDeadline['scheduled_date'];
			this.scheduleDate = creativeDeadline['scheduled_date'];

			await this._updateCreative(this.projectId, creativeDeadline);

			await this._getCreativeData(this.projectId);
			this.isSaved = true;
			this.isEdit = false;
			this.showCountDown = true;
			socketClient.send('creative_created', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});
			// send mail case 34
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］提案日時を変更しました`;
			this.mailContent = `提案日時を変更しました。\n${moment(
				this.dateSelected.selectedDate + ' ' + hours + ':' + minutes
			).format('YYYY年MM月DD日 HH:mm')} に\nCo-modeを開いてください。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 提案日時を変更しました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.isSendCreator = false;
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 提案日時を変更しました`;
			this.mailContent = `提案日時を変更しました。\n${moment(
				this.dateSelected.selectedDate + ' ' + hours + ':' + minutes
			).format('YYYY年MM月DD日 HH:mm')} に\nCo-modeを開いてください。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 提案日時を変更しました`;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
			this.$emit('start-creative-countdown')
		},

		onEdit() {
			console.log('onEdit');
			this.isEdit = true;
		},

		onCancel() {
			this.isEdit = false;
		},
		/**
		 * Get creative Data from API
		 * @param {Integer} projectId
		 */
		async _getCreativeData(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get creative Data failed!';
			}
			this.creativeData = response.data;

			if (Object.keys(this.creativeData).length === 0) {
				return;
			}

			this.isSaved = true;
			this.showQuestionContainer = true;
			this.creativeId = response.data['id'];
			this.creativeStatus = response.data['status'];
			this._filterQuestionList(this.creativeData);

			if (this.creativeData['scheduled_date']) {
				this.counterDate = this.creativeData['scheduled_date'];
				this.scheduleDate = this.creativeData['scheduled_date'];
			}
			this._checkStatus(this.creativeStatus);
		},

		/**
		 * Create new Creative record
		 * @param {Object} content: Creative post object
		 */
		async _createCreative(content) {
			try {
				let response = await CreativeService.post({
					doc_content: content,
				});

				if (!response || response.status !== 200) {
					throw 'Create Creative failed';
				}

				console.log('%c Create Creative successfully', 'color: green');
				this.creativeId = response.data['id'];
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Update Creative
		 * @param {Integer} projectId Project ID
		 * @param {Object} content updating data
		 */
		async _updateCreative(projectId, content) {
			try {
				let response = await CreativeService.put(projectId, {
					doc_content: content,
				});
				if (!response || response.status !== 200) {
					throw 'Update Creative failed';
				}
				console.log('%c Update Creative successfully!', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _getProjectStatus(projectId) {
			try {
				let response = await ProjectService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Project failed!';
				}

				console.log('%c Get Project successfully!', 'color:green');

				return response.data['status'];
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		onDatePickerChange(data) {
			this.dateSelected = data.dateSelected;
			this.hourSelected = !isNaN(data.hourSelected) && data.hourSelected != null ? data.hourSelected : '';
			this.minuteSelected = !isNaN(data.minuteSelected) && data.minuteSelected != null ? data.minuteSelected : '';
			if (
				this.dateSelected.selectedDate === '' ||
				this.hourSelected === '' ||
				this.minuteSelected === ''
			) {
				this.isDisableSetupBtn = true;
			} else {
				this.isDisableSetupBtn = false;
			}
		},

		/**
		 * Check screen status to show and hide elements
		 * @param {String} status Screen status
		 */
		_checkStatus(status) {
			console.log('%c CHECK STATUS CREATIVE SCREEN', 'color: red');
			if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showBottomButton = false;
				this.allowAdditions = false;
				this.isEditAble = false;
				this.showCountDown = false;
			} else if (status === CommonConst.SCREEN_STATUS.TIME_OUT) {
				this.showCountDown = false;
				this.isEditAble = false;
			} else if (
				status === CommonConst.SCREEN_STATUS.COUNTING_DOWN &&
				this.projectInfo['status'] === CommonConst.SCREEN_ID.CREATIVE + 1
			) {
				this.showBottomButton = false;
				this.allowAdditions = false;
				this.isShowDeleteButton = false;
				this.isEditAble = true;
			} else {
				this.showBottomButton = true;
				this.isEditAble = true;
			}
		},
	},

	async created() {
		socketClient.listen('new_creative_question_list_updated', async (data) => {
			console.log('New Question have been answered');
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				console.log('Update Creative Data');
				await this._getCreativeData(this.projectId);

				this.$store.commit('setIsAppProcessing', false);
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getCreativeData(this.projectId);
		this.$emit('start-creative-countdown')
		this.$store.commit('setIsAppProcessing', false);
	},
};
