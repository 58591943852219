/**
 * Concat to standard date string
 * @param {Object} param0 Object contain date time values
 * @returns date string in format "YYYY-MM-DD hh:mm"
 */
function formatToStandardDate({ date, hour, minute }) {
	let standardHour = hour < 10 ? '0' + hour : hour;
	let standardMinute = minute < 10 ? '0' + minute : minute;

	return `${date} ${standardHour}:${standardMinute}`;
}

export { formatToStandardDate };
